import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import { Link } from 'gatsby'
import CTA from "../../components/cta"

export default () => (
  <Layout>

    <SEO title="Bokun specialists and consultation"
        description="Our account managers are Bokun experts and we specialize in setting up and using Bokun accounts"
        pathname="/services/bokun-consulation/"
    />
        
    <Hero 
         headerText="Bokun specialists" 
         subHeaderText="We will help you make the most of Bokun"
            /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>Bokun experts that can help you</h2>
            
            <p>Our team is has been using Bokun for years and we can help guide you on the best practice usage of Bokun and setting up your account.</p>

            <p className="" style={{marginTop: 30, marginBottom: 50}}><Link to="/contact/" className="btn">Contact us for more information</Link></p>
            <CTA />
        </div>

      </div>
    </section>

  </Layout>
)
